import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import VueWechatTitle from 'vue-wechat-title'


import { Toast } from 'vant';

const app = createApp();
app.use(Toast);

createApp(App).use(router).use(VueWechatTitle).mount('#app')