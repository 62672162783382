import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/gzh',
        name: 'Index',
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/pay',
        name: 'Pay',
        meta: {
            title: "钻石充值"
        },
        component: () => import('@/views/Pay.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router